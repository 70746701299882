<template>
  <section class="navigation__slider">
    <div class="container navigation__slider-container">
      <nav class="nav">
        <swiper :options="swiperOption" ref="mySwiper">
          <router-link
            :to="`/wizard/${i}/`"
            class="swiper-slide"
            v-for="(sector, i) in sectors"
            :class="{
              disabled: !sectorFromState.material.id || isInLocation
            }"
            :key="sector.uid"
          >
            <!--:class="{'disabled' : sectorFromState.materialSize.width === 0}"-->
            <div class="slide">
              <div class="slide__img">
                <nav-image :sector-index="i" :is-in-nav="true" />
              </div>
            </div>
          </router-link>
          <div class="swiper-scrollbar" slot="scrollbar"></div>
        </swiper>
        <button
          @click="addFromNav"
          class="nav__save"
          :disabled="(step !== 'Sector' && step !== 'MinorResult') || isInLocation"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.8" cx="12" cy="12" r="12" fill="#ECECEC"></circle>
            <path
              d="M12 18V6M18 12H6"
              stroke="#D6D6D6"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </nav>
    </div>
    <div class="nav__pane">
      <div class="container">
        <div class="row">
          <div class="nav__pane-row">
            <div class="nav__pane-mobile">
              <div class="nav__pane-mobile--header">
                <div class="nav__pane-items--single nav__pane-items--single__active">
                  <div class="number">{{ getStepParams(step).number }}</div>
                  <div class="title">{{ getStepParams(step).name }}</div>
                </div>
                <button @click="isNavShow = !isNavShow" class="btn">
                  <span class="btn__text" v-if="!isNavShow">
                    {{ getStepParams(step).number }} / 6
                  </span>
                  <img
                    class="btn__img"
                    src="@/assets/img/showMore.svg"
                    v-if="!isNavShow"
                    alt="show more"
                  />
                  <img src="@/assets/img/close-nav.svg" alt="❌" v-else />
                </button>
              </div>
              <div class="nav__pane-mobile--body" v-if="isNavShow">
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': isInLocation,
                    'nav__pane-items--single__passed': !isInLocation
                  }"
                  @click="goToLocation"
                >
                  <div class="number">1</div>
                  <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                  <div class="title">
                    Ветровой<br />
                    район
                  </div>
                </div>
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': step === 'Base',
                    'nav__pane-items--single__passed': step !== 'Base' && !isInLocation
                  }"
                  @click="goToBase"
                >
                  <div class="number">2</div>
                  <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                  <div class="title">
                    Тип<br />
                    основания
                  </div>
                </div>
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': step === 'Insulation',
                    'nav__pane-items--single__passed':
                      step !== 'Insulation' && step !== 'Base' && !isInLocation
                  }"
                  @click="goToInsulation"
                >
                  <div class="number">3</div>
                  <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                  <div class="title">Верхний слой<br />теплоизоляции</div>
                </div>
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': step === 'DampCourse',
                    'nav__pane-items--single__passed':
                      step !== 'Insulation' &&
                      step !== 'Base' &&
                      step !== 'DampCourse' &&
                      !isInLocation
                  }"
                  @click="goToDampCourse"
                >
                  <div class="number">4</div>
                  <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                  <div class="title">Гидро<span>-</span><br />изоляция</div>
                </div>
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': step === 'Sector',
                    'nav__pane-items--single__passed':
                      step !== 'Insulation' &&
                      step !== 'Base' &&
                      step !== 'DampCourse' &&
                      step !== 'Sector' &&
                      !isInLocation
                  }"
                  @click="goToSector"
                >
                  <div class="number">5</div>
                  <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                  <div class="title">
                    Параметры<br />
                    участка
                  </div>
                </div>
                <div
                  class="nav__pane-items--single"
                  :class="{
                    'nav__pane-items--single__active': step === 'MinorResult',
                    disabled: sectorFromState.height <= 0
                  }"
                  @click="goToMinorResult"
                >
                  <div class="number">6</div>
                  <div class="title">
                    Промежуточный<br />
                    расчет
                  </div>
                </div>
              </div>
            </div>
            <div class="nav__pane-items">
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active': isInLocation,
                  'nav__pane-items--single__passed': !isInLocation
                }"
                @click="goToLocation"
              >
                <div class="number">1</div>
                <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                <div class="title">
                  Ветровой<br />
                  район
                </div>
              </div>
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active': activeStep(step),
                  'nav__pane-items--single__passed': step !== 'Base' && !isInLocation
                }"
                @click="goToBase"
              >
                <div class="number">2</div>
                <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                <div class="title">
                  Тип<br />
                  основания
                </div>
              </div>
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active': activeStep(step),
                  'nav__pane-items--single__passed':
                    step !== 'Insulation' && step !== 'Base' && !isInLocation
                }"
                @click="goToInsulation"
              >
                <div class="number">3</div>
                <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                <div class="title">Верхний слой<br />теплоизоляции</div>
              </div>
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active': activeStep(step),
                  'nav__pane-items--single__passed':
                    step !== 'Insulation' &&
                    step !== 'Base' &&
                    step !== 'DampCourse' &&
                    !isInLocation
                }"
                @click="goToDampCourse"
              >
                <div class="number">4</div>
                <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                <div class="title">Гидро<span>-</span><br />изоляция</div>
              </div>
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active':  activeStep(step),
                  'nav__pane-items--single__passed':
                    step !== 'Insulation' &&
                    step !== 'Base' &&
                    step !== 'DampCourse' &&
                    step !== 'Sector' &&
                    !isInLocation
                }"
                @click="goToSector"
              >
                <div class="number">5</div>
                <img src="@/assets/img/tick-nav.svg" class="tick" alt="✔" />
                <div class="title">
                  Параметры<br />
                  участка
                </div>
              </div>
              <div
                class="nav__pane-items--single"
                :class="{
                  'nav__pane-items--single__active':  activeStep(step),
                  disabled: sectorFromState.height <= 0
                }"
                @click="goToMinorResult"
              >
                <div class="number">6</div>
                <div class="title">
                  Промежуточный<br />
                  расчет
                </div>
              </div>
            </div>
            <button
              class="btn-result"
              @click="resultFromNav"
              :disabled="(step !== 'Sector' && step !== 'MinorResult') || isInLocation"
            >
              <img src="@/assets/img/calculator.svg" alt="calculate" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import NavImage from '@/components/dump/NavImage'
import { calcLabel, sendStatistic, statisticLabels, steps } from '@/utils/sendStatistic'

export default {
  props: {
    isInLocation: Boolean,
    step: String
  },
  data: () => ({
    isNavShow: false,
    swiperOption: {
      slidesPerView: 7,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false
      },
      breakpoints: {
        992: {
          slidesPerView: 6
        },
        960: {
          slidesPerView: 6
        },
        768: {
          slidesPerView: 5
        },
        520: {
          slidesPerView: 4
        },
        359: {
          slidesPerView: 3
        }
      }
    }
  }),
  components: {
    NavImage
  },
  methods: {
    addFromNav() {
      this.$emit('addFromNav')
    },
    goToLocation() {
      if (!this.isInLocation) {
        this.$emit('goToLocation')
      }
    },
    goToBase() {
      if (this.location.type) {
        this.$emit('goToBase')
      }
    },
    goToInsulation() {
      if (!this.isInLocation) {
        this.$emit('goToInsulation')
      }
    },
    goToDampCourse() {
      if (!this.isInLocation) {
        this.$emit('goToDampCourse')
      }
    },
    goToSector() {
      if (!this.isInLocation) {
        this.$emit('goToSector')
      }
    },
    goToMinorResult() {
      if (!this.isInLocation) {
        this.$emit('goToMinorResult')
      }
    },
    resultFromNav() {
      this.$router.push('/result/')
    },
    proppedSideB(sideB) {
      if (sideB) {
        return sideB
      }
      return 0
    },
    proppedSideL(sideL) {
      if (sideL) {
        return sideL
      }
      return 0
    },
    getStepParams(name) {
      switch (name) {
        case 'Base':
          return { name: 'Тип основания', number: 2 }
        case 'Insulation':
          return { name: 'Верхний слой теплоизоляции', number: 3 }
        case 'DampCourse':
          return { name: 'Гидроизоляция', number: 4 }
        case 'Sector':
          return { name: 'Параметры участка', number: 5 }
        case 'MinorResult':
          return { name: 'Промежуточный расчет', number: 6 }
        default:
          return { name: 'Ветровой район', number: 1 }
      }
    },
    activeStep(step) {
      switch (step) {
        case 'Base':
          this.sendStatistic(steps[1])
          return true
        case 'Insulation':
          this.sendStatistic(steps[2])
          return true
        case 'DampCourse':
          this.sendStatistic(steps[3])
          return true
        case 'Sector':
          this.sendStatistic(steps[4])
          return true
        case 'MinorResult':
          this.sendStatistic(steps[5])
          return true
        default:
          this.sendStatistic(steps[0])
          return false
      }
    },
    sendStatistic(step) {
      const stepEvents = window.dataLayer
        .filter(l => {
          if (l.event) {
            return l.event.includes(`calc-${calcLabel}-step`) || l.event.includes(`calc-${calcLabel}-result`)
          }
        })
        .reverse()

      const isRepeat = stepEvents[0]?.event.includes(step)
      const isFromResultPage = stepEvents[0]?.event.includes('result')

      if (!isRepeat || isFromResultPage) sendStatistic(statisticLabels.steps[step])
    }
  },
  computed: {
    ...mapState({
      location: state => state.location,
      sectors: state => state.sectors,
      defaultSvgParams: state => state.defaultSvgParams
    }),
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  }
}
</script>

<style lang="sass">
nav
  position: relative
  .swiper
    &-container
      .swiper-slide
        &.disabled
          pointer-events: none
.navigation__slider
  .navigation__slider-container
    padding: 0
.nav
  +media((display: (320: grid, 1170: block)))
  +media((grid-template-columns: (320: 12fr 2fr, 992: 1fr rem(102))))
  min-height: rem(64)
  align-items: center
  &__save
    background: rgba($white, .7)
    box-shadow: none
    cursor: pointer
    border: none
    box-sizing: border-box
    border-radius: rem(4)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    transition: .5s
    overflow: hidden
    outline: none
    align-self: center
    margin: 0
    float: none
    +media((position: (320: relative, 1170: absolute)))
    +media((right: (320: unset, 1170: rem(-95))))
    +media((top: (320: unset, 1170: 50%)))
    +media((height: (320: rem(64), 576: rem(80), 992: rem(82))))
    +media((transform: (320: translateY(0), 1170: translateY(-50%))))
    +media((width: (320: 100%, 1170: rem(85))))
    +media((max-width: (320: rem(40), 400: rem(50), 500: rem(60), 576: rem(70), 600: rem(80), 700: rem(85))))
    +media((margin: (320: 0 auto, 768: auto auto auto rem(11), 1170: 0)))
    &:after
      content: "Новый участок"
      position: absolute
      height: 100%
      width: 100%
      top: 0
      left: 0
      color: $white
      background: $green
      font-family: 'Montserrat', sans-serif
      font-style: normal
      font-weight: 700
      font-size: rem(12)
      line-height: rem(15)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      transition: .5s
      opacity: 0
      +media((display: (320: none, 992: flex)))
    @media(any-hover: hover)
      &:hover
        &:after
          opacity: 1
    svg
      circle, path
        transition: .5s
      circle
        fill: $green
      path
        stroke: $white
    &:disabled
      svg
        circle
          fill: $ec
        path
          stroke: $tn_gray
      cursor: no-drop
      &:hover
        &:after
          opacity: 0
nav
  position: relative
  .swiper
    &-container
      width: auto
      +media((margin: (320: 0, 1170: 0 auto)))
      .swiper-slide
        &.disabled
          pointer-events: none
          opacity: .5
        &.router-link-active
          opacity: 1
      &:hover
        .swiper
          &-scrollbar
            &-drag
              background: rgba($red, .5)
        > .swiper-scrollbar
          background: rgba(0, 0, 0, 0.1)
    &-slide
      text-decoration: none
      svg
        +media((max-width: (320: 100%, 1170: 100%)))
        +media((max-height: (320: rem(57), 768: initial)))
        height: auto
      &:before
        height: 100%
        width: 100%
        content: url(~@/assets/img/cog.svg)
        position: absolute
        top: 0
        left: 0
        pointer-events: none
        background: rgba(51, 51, 51, 0.4)
        border: rem(1) solid $tn_gray
        opacity: 0
        transition: .5s
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        box-sizing: border-box
        z-index: 1
      &:hover
        &:before
          opacity: 1
      &.router-link-active
        .slide__info-size
          color: $black
        &:before
          background: $white
          opacity: 1
          border: none
          border-top: rem(4) solid $red
          border-bottom: rem(4) solid transparent
          box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
          content: url(~@/assets/img/cog-active.svg)
    &-scrollbar
      height: rem(4)
      top: 0
      &-drag
        border-radius: rem(12)
        background: rgba($red, .2)
  .swiper-container-horizontal > .swiper-scrollbar
    background: rgba(0, 0, 0, 0.05)
    height: rem(4)
    width: 100%
    left: 0
  .slide
    display: flex
    flex-direction: column
    // justify-content: space-between
    align-items: center
    padding: 0
    background: rgba(246, 246, 246, 0.15)
    box-shadow: inset rem(-1) 0 0 $tn_gray
    height: 100%
    &__img
      line-height: 0
      padding: rem(10) 0
    // &__info
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      font-style: normal
      font-size: rem(12)
      line-height: 1
      font-weight: 500
      +media((display: (320: none, 576: flex)))
      &-size
        color: $black
      &-count
        color: #999999
        padding-left: rem(5)
.nav
  &__pane
    width: 100%
    position: relative
    +media((background: (320: transparent, 960: $white)))
    +media((box-shadow: (320: none, 960: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5))))
    &-row
      position: relative
      width: 100%
      background: $white
      +media((display: (320: grid, 992: flex)))
      grid-template-columns: 12fr 2fr
      +media((box-shadow: (320: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5), 960: none)))
    &-mobile
      flex-direction: column
      text-align: left
      position: relative
      display: block
      +media((display: (320: flex, 992: none)))
      height: 100%
      &--header, &--body
        width: 100%
        background: $white
        .btn
          background: none
          box-shadow: none
          outline: none
          border: none
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          &__text
            font-family: $main-font
            font-style: normal
            font-weight: bold
            font-size: rem(12)
            line-height: rem(18)
            color: $tn_gray_plus
            padding-bottom: rem(9)
          &__img
            opacity: .7
      &--header
        display: grid
        grid-template-columns: 3fr 1fr
        height: 100%
        +media((min-height: (320: rem(64), 576: rem(80))))
        z-index: 2
      &--body
        box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
        position: absolute
        top: 100%
        z-index: 3
        padding: rem(6) 0
    &-items
      width: 100%
      text-align: left
      display: grid
      +media((display: (320: none, 992: grid)))
      +media((padding: (320: rem(13) 0, 992: rem(16) 0)))
      +media((grid-template-columns: (320: 1fr, 992: repeat(6, 1fr))))
      &--single
        display: flex
        +media((flex-direction: (320: row, 992: column)))
        +media((align-items: (320: center, 992: flex-start)))
        +media((padding: (320: rem(10) rem(24), 992: 0 0 0 rem(20))))
        +media((box-shadow: (320: none, 992: inset rem(-1) 0 0 $ec)))
        &:last-of-type
          box-shadow: none
        &.disabled
          pointer-events: none
        .number
          font-style: normal
          font-weight: 600
          font-size: rem(12)
          line-height: rem(30)
          color: $white
          height: rem(20)
          width: rem(20)
          background: $tn_gray
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          border-radius: 50%
          transition: .5s
          +media((margin-bottom: (320: 0, 992: rem(14))))
        .title
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(18)
          color: $tn_gray_plus
          +media((padding-left: (320: rem(15), 992: 0)))
          br
            +media((display: (320: none, 992: inline-block)))
          span
            +media((display: (320: none, 992: inline-block)))
        .tick
          display: none
          +media((margin-bottom: (320: 0, 992: rem(14))))
        &__active
          cursor: auto
          .number
            background: $red
          .title
            font-weight: bold
            color: $black
        &__passed
          cursor: pointer
          .number
            display: none
          .tick
            display: block
          .title
            color: $black
    &-mobile
      width: 100%
    .btn
      &-result
        top: 0
        background: none
        outline: none
        box-shadow: none
        border: none
        transition: .5s
        background: $red
        cursor: pointer
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        max-width: rem(105)
        // +media((box-shadow: (320: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5), 992: none)))
        +media((height: (320: rem(64), 576: rem(80), 992: rem(102))))
        +media((position: (320: relative, 1170: absolute)))
        +media((width: (320: 100%, 992: rem(105))))
        +media((right: (320: initial, 1170: rem(-105))))
        &:hover
          background: $red-hover
        &:disabled
          cursor: no-drop
          background: $ec
          &:hover
            background: $red
            background: $ec
</style>
